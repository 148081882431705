.notFoundPage {
    /*
    background-color: aquamarine;
    */
    margin-top: 1rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.notFoundPage h1 {
    font-size: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*
    background-color: rgb(190, 192, 109);
    */
}

.notFoundPage p {
    font-size: 1.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    text-align: center;
    /*
    background-color: rgb(232, 174, 161);
    */
}