.footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #333;
    color: white;
    padding-top: 1rem;
    padding-bottom: 1rem;
    font-size: 1rem;
    padding-left: 1rem;
    padding-right: 1rem;
    /*
    width: 100%;
    justify-content: center;
    padding-left: .5rem;
    padding-right: 2rem;
    padding-right: .5rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    bottom: 0;
    */
  }
  .footer .contentPlace {
    display: flex;
    max-width: 1000px; 
    width: 100%;
    flex-direction: column;
    
    /*
    background-color: #5f654c;
    padding-left: .5rem;
    padding-right: .5rem;
    justify-content: center;
    align-items: center;
    justify-content: space-between;
    */

  }

  .footer .contentPlace .contentContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;

    /*
    padding-right: 0.5rem;
    padding-left: 2rem;
    background-color: #64857e;
    width: 100%;
    */

  }

  .footer .linkContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: end;
    /*
    background-color: #64857e;
    */
  }

  .footer .terms_of_service_link {
    padding-top: 0.5rem;
  }
  
  .footer p {
    text-align: center;
    /*
    background-color: #446444;
    display: flex;
    */

  }

  .footer .footer_links {
    /*
    display: flex;
    background-color: #875e78;
    */
    color: inherit; /* Цвет текста будет таким же, как у родительского элемента */
    text-decoration: none; /* Убирает подчеркивание */  
    text-align: center;

  }

  .footer .footer_links:hover {
    text-decoration: underline; /* Adds underline on hover */
  }

  .footer .footer_links:active {
    color: rgb(211, 211, 211); /* Changes color to orange when clicked */
  }

  /*@media (max-width: 940px) {
    .footer .contentPlace .contentContainer {
      padding-right: 0;
    }
  }*/

  
  @media (max-width: 420px) {
    .footer .contentPlace .contentContainer {
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }

    .footer .linkContainer {
      align-items: center;
    }

    .footer .contentPlace .contentContainer {

      padding-left: 1rem;
      padding-right: 1rem;
      /*
      width: 100%;
      */
  
    }

    .footer p {
      padding-bottom: 1rem;
  
    }

    

  }

  