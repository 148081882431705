.introducing_placement {
    /*

    */
    background-color: #ffffff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
  }

  .introducing_placement .introducing_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px; 
    
    /*
    background-color: rgb(215, 147, 186);
    max-width: 768px; 
    flex-wrap: wrap;
    padding: 2em;
    background-color: white;
    border-radius: 8px;
      */
  }

  .introducing_placement h2 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #000000;
    padding-top: 3rem;
    padding-bottom: 3rem;

  }
  
  .introducing_placement .description_placement {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 100%;
    /*
    background-color: rgb(199, 215, 147);

      */
  }

  .introducing_placement .description_placement .img_placement img{
    display: flex;
    height: auto;
    max-width: 500px;
    width: 500px;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-left: -13px;
    
    /*
    background-color: rgb(166, 224, 248);
    width: 100%;
    */
  } 

  .introducing_placement .text_description {
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-left: 2rem;
    font-size: 1.5rem;
    color: #000000;
    text-align: start;

    padding-top: 3.5rem;
    /*
    background-color: beige;
    */

  }

  /*@media (max-width: 850px) {*/
  @media (max-width: 940px) {
    .introducing_placement .description_placement {
      flex-direction: column;
      align-items: center;
      padding-top: 0;

      /*
  
        */
    }

    .introducing_placement .description_placement .text_description{
      text-align: center;
      padding-left: 0rem;
      padding-top: 2rem;

      /*
  
        */
    }

    .introducing_placement .description_placement .img_placement img{
      width: 100%;
      margin-left: 0;
      padding-bottom: 0;
    }
  }


  