.slider_container {
    /*
    flex-direction: row;
    justify-content: center;
    align-items: center;
    display: flex;
    background-color: #928de9;
    */
    width: 100%;
    overflow: hidden;
    padding-left: 2.5rem;
    padding-right: 2.5rem;
    padding-bottom: 3rem;
    /*
    width: 1000px; 
    height: 700px;
    margin: 0 auto;
    */
}

/*
.slider_container div {
    background-color: #ff9494;
    justify-content: center;
    align-items: center;
    margin: 10px;
}
*/

.slider_container .img_placement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    padding-left: .5rem;
    padding-right: .5rem;
    
    /*
    background-color: #8b9aff;
    margin: 10px 10px;
    max-width: 280px;
    */


}
.slider_container .img_placement img {
    /*
    max-width: 200px;
    background-color: #cadb85;
    */
    width: 100%;
    max-width: 280px;

    height: auto;

}
.slider_container .img_placement h3 {
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
    color: #000000;
    padding-top: 1rem;
    padding-bottom: 1rem;

}

  .slick-prev:before, .slick-next:before {
    color: #333;
    font-size: 25px;
    /*
    */
    z-index: 1;
  }

@media (max-width: 1030px) {
    .slider_container {
        max-width: 700px;
    }
    
}

@media (max-width: 750px) {
    .slider_container {
        max-width: 400px;
    }
    
}