.privacy-policy {
  
  background-color: #eee;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  width: 100%;
  padding-top: 2rem;
  padding-bottom: 2rem;
  
}

.privacy-policy .container {
  max-width: 1000px;
  /*
  margin: 30px auto;
  margin-top: 2rem;
  */
  padding: 20px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
}
.privacy-policy h1, h2 {
  color: #333;
}
.privacy-policy h1 {
  font-size: 2rem;
  margin-bottom: 0.5rem;
}
.privacy-policy h2 {
  font-size: 1.7rem;
  margin-top: 3rem;
  margin-bottom: 1rem;
  /*
  */
}
.privacy-policy p {
  /*
  margin: 1rem 0;
  */
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
}
.privacy-policy .contact {
  margin-top: 2rem;
  /*
  font-style: italic;
  */
}

.privacy-policy li {
  list-style-type: circle;
  margin-left: 1.5rem;
  margin-bottom: 1rem;
  font-size: 1.5rem;
  line-height: 1.5;
}

.privacy-policy .link {
  text-decoration: none; /* Removes default underline */
  /*
  */
}

.privacy-policy .link:hover {
  text-decoration: underline; /* Adds underline on hover */
}

.privacy-policy .link:active {
  color: orange; /* Changes color to orange when clicked */
}

/*.privacy-policy {
    padding: 2em;
    background-color: white;
    margin: 1em;
    border-radius: 8px;
  }
  
  @media (max-width: 768px) {
    .privacy-policy {
      padding: 1em;
      margin: 0.5em;
    }
  }
  
  @media (max-width: 480px) {
    .privacy-policy h2 {
      font-size: 1.5em;
    }
  
    .privacy-policy p {
      font-size: 1em;
    }
  }*/
  