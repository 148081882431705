

/* Header.css */
.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  border-color: #ddd;
  border-width: 0;
  border-style: solid;  
  border-bottom-width: 1px;
  position: fixed;
  z-index: 1000; 
  justify-content: center;
  width: 100%;
  background-color: #ffffff;
  padding-right: 1rem;
  padding-left: 1rem;
  /* 

  background-color: #c3eabd;
  height: 100px;
  align-items: center;
  display: inline;
  */
}

.allMenu { 
  /*
  margin-left: .5rem;
  margin-right: .5rem;
  
  height: 58px;
  max-width: 1300px; 
  width: 1000px;
  padding-right: 1rem;
  padding-left: 1rem;
  background-color: #f57878;
  */
  max-width: 1000px; 
  width: 100%;
  padding-top: 8px;
  padding-bottom: 8px;
  display: flex;
  flex:content;
  align-items: start;
  justify-content: center;
  flex-direction: column;

}

.horizontalMenu {
  /*
  margin-right: .5rem;
  margin-left: .5rem;
  width: 100%;
  padding-right: 1.5rem;
  padding-left: 1.5rem;
  max-width: 1000px; 
  width: 100%;
  align-items: start;
  background-color: #73d38e;
  */
  display: flex;
  justify-content: space-between;
  flex-direction: row;
}

.logo {
  /*
  background-color: rgb(237, 189, 207);
  */
  display: flex;
  flex-direction: row;
  align-items: center;
  
}

.logo .logoTxt {
  font-size: 1.5rem;
  color: black;
  padding-left: .5rem;
} 

.logo .logoImg {
  /*
  background-color: rgb(238, 144, 144);
  */
  
  
  display: flex;
  flex-direction: row;
  align-items: center;
} 

.logo .logoImg img {
  width: auto;
  height: 42px;
}


.subMenu {
  /*
  background-color: rgb(184, 166, 195);
  */
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  padding: 0;

}

.allMenu ul {
  list-style: none;
}

.allMenu a {
  text-decoration: none;
  color: black;
}

.subMenu ul {
  /*
  background-color: rgb(237, 249, 129);
  */
  display: flex;
  flex-direction: row;
  margin: 0;
  padding: 0;
}

.subMenu ul li {
  /*
  background-color: rgb(143, 216, 227);
  */
  margin: 0 15px;
  padding: 0;
}

.menu-icon {
  /*
  background-color: rgb(255, 155, 155);
  */
  display: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0;  
  margin: 0;  

  font-size: 24px;
  border: none;
  color: #ffffff;
  cursor: pointer;
}

.appearingMenu {
  display: none;
  width: 100%;
  /*
  background-color: rgb(255, 155, 155);
  */
}

.appearingMenu ul{
  /*
  background-color: rgb(164, 190, 255);
  */
  
}
 
@media (max-width: 600px) { /*HomePage.css*/
  .subMenu {
    display: none;
  }


  .appearingMenu.open {
    display: flex;
  }

  .menu-icon {
    display: flex;
  }

}
/*





  .menu.open {
    display: flex;
  }

  .menu ul {
    flex-direction: column;
    width: 100%;
  }

  .menu ul li {
    margin: 10px 0;
    text-align: center;
  }
  



body {
  padding-top: 100; 
}*/
