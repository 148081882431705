.main_placement {
    /*
    min-width: 350px;
    max-width: 768px; 
    height: 100%;
    padding: 58px 0 0 0;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    background-color: #ffc0c0;
    */
    background-color: #eee;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding-left: 1rem;
    padding-right: 1rem;
  
  }
  
  .mainsection_container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px; 
    padding-top: 3rem;
    padding-bottom: 3rem;
    
    /*
    background-color: rgb(215, 147, 186);
    max-width: 768px; 
    flex-wrap: wrap;
    padding: 2em;
    background-color: white;
    border-radius: 8px;
      */
    }
  
    .mainsection_container .app_showcase_placement {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: end;
      width: 65%;
      /*
      padding-left: 0.5rem;
      padding-right: 0.5rem;
      background-color: rgb(150, 194, 150);
      min-width: 300px;
      height: 100%;
      */
    }
  
    .mainsection_container .img_placement {
      /*
      min-width: 300px;
      background-color: rgb(179, 170, 255);
      */
      display: flex;
      width: 35%;
      flex-direction: column;
      justify-content: center;
      
      align-items: end;
      
    }
  
    .mainsection_container .img_placement img {
      /*
      width: 100%;
      */
      display: flex;
      height: auto;
      max-width: 330px;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      
      width: 100%;
      /*
      padding-top: 1.5rem;
      padding-bottom: 1.5rem;
      padding-left: 0;
      padding-right: 5px;
      background-color: rgb(145, 214, 205);
      margin: 1em auto;
      */
    }
  
    .mainsection_container .img_placement h1 {
      display:none;
    }
  
    
  
    .mainsection_container .app_showcase_container {
      display: flex;
      flex-direction: column;
      align-items: start;
      justify-content: center;
      padding-right: 10px;
  
      /*
      padding-left: 1rem;
      background-color: rgb(255, 210, 210);
      height: 400px;
      height: 100%;
      */
    }
  
    .mainsection_container .app_showcase_container .app_showcase_subcontainer{
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: start;
      /*
      background-color: rgb(164, 204, 218);
      */
    }
  
    .mainsection_container h1 {
      display: flex;
      font-size: 2rem;
      padding-bottom: 2rem;
      flex-direction: column;
      /*
      background-color: rgb(202, 252, 186);
      padding-top: 2rem;
      margin: 1em auto;
      */
    }
  
    /*.mainsection_container h1 span {
      background-color: rgb(157, 174, 213);
  
    }*/
  
    .mainsection_container .app_showcase_description {
      font-size: 1.5rem;
      padding-bottom: 1.5rem;
      /*
      background-color: rgb(160, 217, 211);
      padding-top: 1.5rem;
      */
    }
  
    .mainsection_container .app_store_buttons_container {
      display: flex;
      flex-direction: row;
      justify-content: start;
      align-items: center;
      /*
      flex-wrap: wrap;
      background-color: rgb(192, 197, 255);
      */
    }
  
  
    .mainsection_container .app_showcase_warning {
      /*
      background-color: rgb(245, 172, 197);
      */
      font-size: 1rem;
      font-style: italic;
      padding-left: 1rem;
      text-align: left;
      
    }
  
    .mainsection_container .app_store_buttons_container img{
      /*
      background-color: rgb(156, 218, 165);
      */
      display: flex;
    }
  
    @media (max-width: 940px) {
      .mainsection_container {
        flex-direction: column-reverse;
        /*
        padding-bottom: 1.5rem;
        */
      }
  
      .mainsection_container .img_placement {
        align-items: center;
        width: 100%;
        /*
        padding-left: 1rem;
        padding-right: 1rem;
        */
      }
  
      .mainsection_container .app_showcase_placement {
        align-items: center;
        width: 100%;
        padding-left: 0;
        
        /*
        padding-right: 0.5rem;
        */
      }
  
      .mainsection_container .app_showcase_container {
        /*
        background-color: aquamarine;
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        */
        max-width: 700px;
        align-items: center;
        padding-right: 0;
      }
  
      .mainsection_container .app_showcase_container h1 {
        /*
        padding-top: 1rem;
        text-align: center;
        */
        display: none;
      }
  
      .mainsection_container .app_showcase_container .app_showcase_description {
        
        text-align: center;
      }
  
      .mainsection_container .app_showcase_container .app_showcase_subcontainer {
        align-items: center;
      }
  
      .mainsection_container .img_placement img {
        padding-left: 0;
        padding-right: 0;
        padding-top: 3rem;
        padding-bottom: 3rem;
      }
  
      .mainsection_container .img_placement h1 {
        display:flex;
        text-align: center;
        padding-bottom: 0rem;
        /*
        padding-top: 2rem;
        */
      }
  
  
  
    }
  
    /*@media (max-width: 254px) {*/
    @media (max-width: 290px) {
      .mainsection_container .app_store_buttons_container {
        flex-direction: column-reverse;
      }
  
      .mainsection_container .app_showcase_warning {
        padding-bottom: .5rem;
        padding-left: 0;
        text-align: center;
      }
  
    }
  
  /*
    
    
    
    @media (max-width: 480px) {
      .mainsection_container h1 {
        font-size: 1.5em;
      }
    
      .mainsection_container p {
        font-size: 1em;
      }
    }*/
    