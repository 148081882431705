* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
}

html {
  font-size: 16px;
}

body {
  font-family: Arial, sans-serif;
  margin: 0;
  padding: 0;
  
  background-color: #ffffff;
  height: 100%;
  /*
  background-color: #f5f5f5;
  width: 100%;
  margin: 0 20px;
  */
}

.maindiv {
  display: flex;
  flex-direction: column;
  min-height: 100vh;

  /*
  background-color: #ffaeae;
  height: 100%;
  padding: 50px;
  margin: 20px;
  width: 100%;
  */
} 

.pagePlace {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: start;
  flex:1;
  padding-top: 58px;

  
  
  
  background-color: #eee;
  /*
  padding-bottom: 2rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #eee;
  height: 900px;
  height: 300px;
  */
}

/*.page {
  /*
  min-width: 350px;
  max-width: 768px; 
  height: 100%;
  
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #b6ed77;
}*/
/*
.div2 {
  display: flex;
  height: 100px;
  width: 100px;
  display: inline;
  display: inline-flex;
  margin: 20px;
  background-color: #78164b;
}
  */

/*
.app2 {
  background-color: rgb(117, 196, 19);
}

nav {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

nav a {
  color: white;
  margin: 0.5em;
  text-decoration: none;
}

section {
  padding: 2em;
  background-color: white;
  margin: 1em;
  border-radius: 8px;
}

h1, h2, h3 {
  color: #333;
}

img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
}

@media (max-width: 768px) {
  nav a {
    margin: 0.3em;
  }

  section {
    padding: 1em;
    margin: 0.5em;
  }

  h1, h2, h3 {
    font-size: 1.5em;
  }

  p {
    font-size: 1em;
  }
}

@media (max-width: 480px) {
  nav a {
    font-size: 0.9em;
    margin: 0.2em;
  }

  h1, h2, h3 {
    font-size: 1.2em;
  }

  p {
    font-size: 0.9em;
  }
}*/
