.features_placement {
    /*

    */
    background-color: #fff;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 1rem;
    /*
    */
  }

  .features_placement .features_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px; 
    
    /*
    background-color: rgb(215, 147, 186);
    max-width: 768px; 
    flex-wrap: wrap;
    padding: 2em;
    background-color: white;
    border-radius: 8px;
      */
  }

  .features_placement h2 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #000000;
    padding-top: 3rem;
    padding-bottom: 3rem;

  }

  .feature_list_placement {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: start;

  }

  .features_placement .feature_item_1,
  .features_placement .feature_item_2  {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    padding-bottom: 3rem;
    width: 50%;
    
    
  }

  .features_placement .feature_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: start;
    width: 100%;
    
  }

  /*.features_placement .feature_list:last-child {
    padding-bottom: 0rem;
  }*/

  .features_placement .feature_item_1 {
    padding-right: 1.5rem;
  }

  .features_placement .feature_item_2 {
    padding-left: 1.5rem;
  }

  .features_placement .feature_item_description h3{
    font-size: 1.5rem;
    font-weight: bold;
    padding-bottom: 1rem;
    
  }

  .features_placement .feature_item_description p{
    font-size: 1.5rem;
    
  }
  
  .features_placement .feature_item_image img {
    max-width: 75px;
    width: 75px;
    padding-right: 1rem;
  }

  @media (max-width: 850px) {
    .features_placement .feature_list {
      flex-direction: column;
    }

    .features_placement .feature_item_1,
    .features_placement .feature_item_2 {
      padding-left: 0rem;
      padding-right: 0rem;
      width: 100%;
    }
  }

  