.screens_placement {
    /*

    */
    background-color: #eee;
    display: flex;
    width: 100%;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: #000000;
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 3rem;
  }

  .screens_placement .screens_container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    max-width: 1000px; 
    /*
    background-color: aquamarine;
    */

    
  }

  .screens_placement h2 {
    text-align: center;
    font-size: 1.8rem;
    font-weight: bold;
    color: #000000;
    padding-top: 3rem;
    padding-bottom: 3rem;

  }

  /*.screens_placement .screens_container div {
  }*/

  /*.screens_placement .screens_container h2 {
    background-color: rgb(241, 194, 175);
    width: 100%;
  }*/