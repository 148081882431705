.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: start;
    flex:1;
    width: 100%;
    
    /*
    padding-bottom: 2rem;
    background-color: #eee;
    height: 5000px;
    */

}

.homepage p {
    line-height: 1.3;
    /*
    */
}